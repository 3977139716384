var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      style: _vm.rotationStyles,
      attrs: {
        viewBox: "0 0 16 15",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
        stroke: "currentColor",
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.18329 0.842714L0.815366 6.67612C0.604004 6.90217 0.5 7.20113 0.5 7.50009C0.5 7.79905 0.604842 8.09729 0.814527 8.32479L6.18245 14.1582C6.60182 14.6139 7.2812 14.6139 7.70057 14.1582C8.11994 13.7025 8.11994 12.9642 7.70057 12.5084L4.16361 8.66677H14.4566C15.0501 8.66677 15.5 8.14432 15.5 7.5329C15.5 6.92149 15.0501 6.33341 14.4566 6.33341H4.16361L7.69973 2.49065C8.1191 2.03492 8.1191 1.29663 7.69973 0.840891C7.28036 0.385156 6.60266 0.386979 6.18329 0.842714Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }