var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showMollieComponent,
            width: "505px",
            bkcolor: "#F7F7F7",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "price-list-mollie-close-button",
              staticStyle: { "margin-left": "auto" },
              on: { click: _vm.onMollieFormClose },
            },
            [
              _c("close-circle-icon", {
                staticClass:
                  "price-list-mollie-close-button-icon pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _vm.selectedPlan
            ? _c("mollie", {
                attrs: {
                  selectedPlan: _vm.selectedPlan,
                  subscriptionPlanId: _vm.selectedPlan.id,
                  isFirstInvoice: _vm.isFirstInvoice,
                  isModal: true,
                  supportedPaymentMethods: _vm.supportedPaymentMethods,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.changePlanConfirmationTitle,
            active: _vm.showConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showConfirmation = $event
            },
          },
        },
        [
          _vm.selectedPlan && _vm.selectedPlan.level > this.CURRENT_PLAN_LEVEL
            ? _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("vue.confirmQuestion")) + "\n    "
                ),
              ])
            : _c("p", { staticClass: "mb-2" }, [
                _vm._v("\n      " + _vm._s(_vm.DOWNGRADED_FROM) + "\n    "),
              ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: { click: _vm.onConfirmChangePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: { click: _vm.onCloseChangePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancelChangePlan")))]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.changePlanConfirmationTitle,
            active: _vm.showMollieConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showMollieConfirmation = $event
            },
          },
        },
        [
          _vm.selectedPlan && _vm.selectedPlan.level > this.CURRENT_PLAN_LEVEL
            ? _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("vue.confirmQuestion")) + "\n    "
                ),
              ])
            : _vm._e(),
          _vm.companyData && _vm.companyData.paymentStatus === "on-trial"
            ? _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("vue.confirmQuestion")) + "\n    "
                ),
              ])
            : _c("p", { staticClass: "mb-2" }, [
                _vm._v("\n      " + _vm._s(_vm.DOWNGRADED_FROM) + "\n    "),
              ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: { click: _vm.onConfirmChangeMolliePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: { click: _vm.onCloseChangeMolliePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancelChangePlan")))]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.changePlanConfirmationTitle,
            active: _vm.showMollieUpgradeConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showMollieUpgradeConfirmation = $event
            },
          },
        },
        [
          _vm.selectedPlan && _vm.selectedPlan.level > this.CURRENT_PLAN_LEVEL
            ? _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.confirmQuestion"))),
              ])
            : _vm._e(),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#3034F7", type: "filled" },
              on: { click: _vm.onConfirmUpgradeMolliePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#3034F7" },
              on: { click: _vm.onCloseUpgradeMolliePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancelChangePlan")))]
          ),
          _c("Promocode"),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("pricing.cancelSubscription"),
            active: _vm.showCancelConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showCancelConfirmation = $event
            },
          },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("pricing.cancelSubscriptionMessage")) +
                "\n    "
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: { click: _vm.onConfirmCancelPlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: { click: _vm.onCloseCancelPlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancelChangePlan")))]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("pricing.cancelSubscription"),
            active: _vm.showMollieCancelConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showMollieCancelConfirmation = $event
            },
          },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("pricing.cancelSubscriptionMessage")) +
                "\n    "
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: { click: _vm.onConfirmCancelMolliePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: { click: _vm.onCloseCancelMolliePlan },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancelChangePlan")))]
          ),
        ],
        1
      ),
      _c(
        "vx-card",
        { staticClass: "p-0" },
        [
          _c(
            "vs-row",
            { staticClass: "mt-5" },
            [
              _c("vs-col", { attrs: { "vs-type": "flex" } }, [
                _c(
                  "div",
                  { staticClass: "price-list" },
                  [
                    _c("div", { staticClass: "price-list__heading" }, [
                      _c("h3", { staticClass: "price-list__heading__title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("pricing.chooseTheRightPlanForYou"))
                        ),
                      ]),
                    ]),
                    !_vm.isLoadingSubscriptionPlans &&
                    _vm.filteredSubscriptionPlans.length
                      ? [
                          _c("div", { staticClass: "price-list__plans" }, [
                            _c(
                              "div",
                              { staticClass: "pricing-plans__heading" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__heading__button",
                                    class: {
                                      "pricing-plans__heading__button--current":
                                        !_vm.isYearly,
                                    },
                                    on: {
                                      click: function () {
                                        return _vm.setPlanInterval(false)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pricing-plans__heading__button__title",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("capitalizeFirstLetter")(
                                              _vm.$t("pricing.monthly")
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pricing-plans__heading__button__label",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("pricing.noSavings"))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__heading__button",
                                    class: {
                                      "pricing-plans__heading__button--current":
                                        _vm.isYearly,
                                    },
                                    on: {
                                      click: function () {
                                        return _vm.setPlanInterval(true)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pricing-plans__heading__button__title",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("capitalizeFirstLetter")(
                                              _vm.$t("pricing.yearly")
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pricing-plans__heading__button__label",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("generateRightTranslations")(
                                              "10%",
                                              _vm.$i18n.locale
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            !_vm.isOpenedFeaturesListOnMobile
                              ? _c(
                                  "div",
                                  { staticClass: "price-list__plans-list" },
                                  _vm._l(
                                    _vm.filteredSubscriptionPlans,
                                    function (plan) {
                                      return _c(
                                        "div",
                                        {
                                          key: plan.id,
                                          staticClass:
                                            "price-list__plans-list__item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "price-list__plans-list__item__heading",
                                              class: {
                                                "price-list__plans-list__item__heading--active":
                                                  plan.isCurrentPlan,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__heading__title",
                                                },
                                                [_vm._v(_vm._s(plan.title))]
                                              ),
                                              plan.isNewPlan
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "price-list__plans-list__item__heading__label-summary",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.ACTIVE_FROM
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "price-list__plans-list__item__body",
                                            },
                                            [
                                              _vm.SHOW_PLAN_AS_FREE(plan.id)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "pricing-plans__list__item__content__price__currency",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__amount",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "features.freeOfCharge"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price",
                                                    },
                                                    [
                                                      !plan.isCustomPrice
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pricing-plans__list__item__content__price__currency",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.currentCurrencySymbol
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__amount",
                                                          class: {
                                                            "pricing-plans__list__item__content__price__amount--custom-price":
                                                              plan.isCustomPrice,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                plan.isCustomPrice
                                                                  ? _vm.$t(
                                                                      plan.quote
                                                                    )
                                                                  : _vm.planPrice(
                                                                      plan
                                                                    )
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                          !plan.isCustomPrice
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "pricing-plans__list__item__content__price__amount__period",
                                                                },
                                                                [_vm._v("/mo")]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__heading__cost",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      plan.isCustomPrice
                                                        ? _vm.$t(plan.team)
                                                        : _vm.$t(plan.users)
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__heading__label-action",
                                                  class: _vm.SET_PLAN_CLASS(
                                                    plan,
                                                    "heading-label"
                                                  ),
                                                },
                                                [
                                                  !_vm.checkIsAbilityToUpdatePlan(
                                                    plan
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "price-list__plans-list__item__heading__label-action__text",
                                                          class: {
                                                            "price-list__plans-list__item__heading__label-action__text--disabled":
                                                              _vm.HAS_PADDLE_SUBSCRIPTION ||
                                                              _vm.HAS_CANCELLED_PADDLE_MOLLIE_SUBSCRIPTION,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onChoosePlan(
                                                                plan
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          plan.isCurrentPlan
                                                            ? [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "pricing.currentPlan"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            : plan.isNewPlan
                                                            ? [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      _vm.planChangeText(
                                                                        plan
                                                                      )
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      _vm.planChangeText(
                                                                        plan
                                                                      )
                                                                    )
                                                                  ) + " "
                                                                ),
                                                              ],
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              plan.isNewPlan &&
                                              _vm.DOWNGRADED_FROM_TEXT
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "price-list__plans-list__item__downgrade",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.DOWNGRADED_FROM_TEXT
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__body__options-list",
                                                },
                                                [
                                                  _vm._l(
                                                    plan.newOptions,
                                                    function (option) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: option.id,
                                                          staticClass:
                                                            "price-list__plans-list__item__body__options-list__item",
                                                          class: {
                                                            "price-list__plans-list__item__body__options-list__item--boldify":
                                                              option.boldify,
                                                          },
                                                        },
                                                        [
                                                          _c("ThinCheckIcon", {
                                                            staticClass:
                                                              "price-list__plans-list__item__body__options-list__item__icon",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                option.description
                                                                  ? option.description
                                                                  : ""
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  plan.isEnterprisePlan
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "price-list__plans-list__item__body__options-list__item",
                                                        },
                                                        [
                                                          _c("AmpersandIcon", {
                                                            staticClass:
                                                              "price-list__plans-list__item__body__options-list__item__icon",
                                                          }),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "pricing.more"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              plan.title &&
                                              plan.title.toLowerCase() !==
                                                "free"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "price-list__plans-list__item__body__optional",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "price-list__plans-list__item__body__optional__position",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "price-list__plans-list__item__body__optional--header",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "vue.optional"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "price-list__plans-list__item__body__options-list__vega-option",
                                                            },
                                                            [
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "price-list__plans-list__item__body__options-list__item price-list__plans-list__item__body__options-list__item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "vs-icon",
                                                                    {
                                                                      staticClass:
                                                                        "price-list__plans-list__item__body__options-list__item__icon",
                                                                      attrs: {
                                                                        size: "18px",
                                                                        icon: "add",
                                                                        "icon-pack":
                                                                          "material-icons",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pricing.aiOption"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "price-list__plans-list__item__body__options-list__item price-list__plans-list__item__body__options-list__item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "vs-icon",
                                                                    {
                                                                      staticClass:
                                                                        "price-list__plans-list__item__body__options-list__item__icon",
                                                                      attrs: {
                                                                        size: "18px",
                                                                        icon: "add",
                                                                        "icon-pack":
                                                                          "material-icons",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pricing.cbOption"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          plan.title &&
                                          plan.title.toLowerCase() === "basic"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__features-list-toggler__button",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.isOpenedBasicFeaturesList =
                                                            !_vm.isOpenedBasicFeaturesList
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.isOpenedBasicFeaturesList
                                                              ? _vm.$t(
                                                                  "features.hideAllBasicFeatures"
                                                                )
                                                              : _vm.$t(
                                                                  "features.seeAllBasicFeatures"
                                                                )
                                                          ) +
                                                          "\n\n                      "
                                                      ),
                                                      _vm.isOpenedBasicFeaturesList
                                                        ? _c("img", {
                                                            staticClass:
                                                              "pricing-plans__features-list-toggler__button__icon",
                                                            attrs: {
                                                              src: require("@/assets/images/register-funnel/minus-circle.svg"),
                                                              alt: "Hide list",
                                                            },
                                                          })
                                                        : _c("img", {
                                                            staticClass:
                                                              "pricing-plans__features-list-toggler__button__icon",
                                                            attrs: {
                                                              src: require("@/assets/images/register-funnel/plus-circle.svg"),
                                                              alt: "Open list",
                                                            },
                                                          }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : plan.title &&
                                              plan.title.toLowerCase() === "pro"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__features-list-toggler__button",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.isOpenedProFeaturesList =
                                                            !_vm.isOpenedProFeaturesList
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.isOpenedProFeaturesList
                                                              ? _vm.$t(
                                                                  "features.hideAllProFeatures"
                                                                )
                                                              : _vm.$t(
                                                                  "features.seeAllProFeatures"
                                                                )
                                                          ) +
                                                          "\n\n                      "
                                                      ),
                                                      _vm.isOpenedProFeaturesList
                                                        ? _c("img", {
                                                            staticClass:
                                                              "pricing-plans__features-list-toggler__button__icon",
                                                            attrs: {
                                                              src: require("@/assets/images/register-funnel/minus-circle.svg"),
                                                              alt: "Hide list",
                                                            },
                                                          })
                                                        : _c("img", {
                                                            staticClass:
                                                              "pricing-plans__features-list-toggler__button__icon",
                                                            attrs: {
                                                              src: require("@/assets/images/register-funnel/plus-circle.svg"),
                                                              alt: "Open list",
                                                            },
                                                          }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__features-list-toggler__button",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.isOpenedEnterpriseFeaturesList =
                                                            !_vm.isOpenedEnterpriseFeaturesList
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.isOpenedEnterpriseFeaturesList
                                                              ? _vm.$t(
                                                                  "features.hideAllEnterpriseFeatures"
                                                                )
                                                              : _vm.$t(
                                                                  "features.seeAllEnterpriseFeatures"
                                                                )
                                                          ) +
                                                          "\n\n                      "
                                                      ),
                                                      _vm.isOpenedEnterpriseFeaturesList
                                                        ? _c("img", {
                                                            staticClass:
                                                              "pricing-plans__features-list-toggler__button__icon",
                                                            attrs: {
                                                              src: require("@/assets/images/register-funnel/minus-circle.svg"),
                                                              alt: "Hide list",
                                                            },
                                                          })
                                                        : _c("img", {
                                                            staticClass:
                                                              "pricing-plans__features-list-toggler__button__icon",
                                                            attrs: {
                                                              src: require("@/assets/images/register-funnel/plus-circle.svg"),
                                                              alt: "Open list",
                                                            },
                                                          }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pricing-plans__features-list-toggler",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pricing-plans__features-list-toggler__button",
                                  on: {
                                    click: function ($event) {
                                      _vm.isOpenedFeaturesList =
                                        !_vm.isOpenedFeaturesList
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.isOpenedFeaturesList
                                          ? _vm.$t("features.hideAllFeatures")
                                          : _vm.$t("features.seeAllFeatures")
                                      ) +
                                      "\n\n                "
                                  ),
                                  _vm.isOpenedFeaturesList
                                    ? _c("img", {
                                        staticClass:
                                          "pricing-plans__features-list-toggler__button__icon",
                                        attrs: {
                                          src: require("@/assets/images/register-funnel/minus-circle.svg"),
                                          alt: "Hide list",
                                        },
                                      })
                                    : _c("img", {
                                        staticClass:
                                          "pricing-plans__features-list-toggler__button__icon",
                                        attrs: {
                                          src: require("@/assets/images/register-funnel/plus-circle.svg"),
                                          alt: "Open list",
                                        },
                                      }),
                                ]
                              ),
                            ]
                          ),
                          _vm.isOpenedFeaturesList
                            ? _c(
                                "div",
                                { staticClass: "pricing-plans__features" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features__heading",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features__heading__list",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features__heading__list__item",
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features__heading__list__item__title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("features.free")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__list__item__content__price",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "pricing-plans__list__item__content__price__currency",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__amount",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "features.freeOfCharge"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features__heading__list__item",
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features__heading__list__item__title",
                                                },
                                                [_vm._v("Basic")]
                                              ),
                                              _vm.SHOW_PLAN_AS_FREE(
                                                _vm.isYearly
                                                  ? "M_BASIC_ANNUAL"
                                                  : "M_BASIC_MONTHLY"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "pricing-plans__list__item__content__price__currency",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__amount",
                                                        },
                                                        [_vm._v("Free")]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__currency",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.currentCurrencySymbol
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__amount",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.isYearly
                                                                  ? _vm.basicPriceAnnual
                                                                  : _vm.basicPriceMonthly
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "pricing-plans__list__item__content__price__amount__period",
                                                            },
                                                            [_vm._v("/mo ")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features__heading__list__item",
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features__heading__list__item__title",
                                                },
                                                [_vm._v("Pro")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__list__item__content__price",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__currency",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentCurrencySymbol
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__amount",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.isYearly
                                                              ? _vm.proPriceAnnual
                                                              : _vm.proPriceMonthly
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__amount__period",
                                                        },
                                                        [_vm._v("/mo")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features__heading__list__item",
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features__heading__list__item__title",
                                                },
                                                [_vm._v("Enterprise")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__list__item__content__price",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pricing.customQuote"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-props",
                                    },
                                    _vm._l(
                                      _vm.featuresList,
                                      function (
                                        featureSection,
                                        featureSectionIndex
                                      ) {
                                        return _c(
                                          "div",
                                          { key: featureSection.title },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pricing-plans__features-props__item-heading",
                                                class: {
                                                  "pricing-plans__features-props__item-heading--rounded":
                                                    featureSectionIndex === 0,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      featureSection.title
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              featureSection.items,
                                              function (feature) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: feature.title,
                                                    staticClass:
                                                      "pricing-plans__features-props__item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pricing-plans__features-props__item__value",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pricing-plans__features-props__item__value__title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                feature.title
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pricing-plans__features-props__item__value__description",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                feature.description
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pricing-plans__features-props__item__marks-list",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pricing-plans__features-props__item__marks-list__item",
                                                          },
                                                          [
                                                            feature.isCustomField
                                                              ? [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        feature.customTextFree
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              : [
                                                                  feature.isFree
                                                                    ? _c(
                                                                        "ThinCheckIcon",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#3034F7",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              src: require("@/assets/images/register-funnel/cross.svg"),
                                                                              alt: "Check",
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pricing-plans__features-props__item__marks-list__item",
                                                          },
                                                          [
                                                            feature.isCustomField
                                                              ? [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        feature.customTextBasic
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              : [
                                                                  feature.isBasic
                                                                    ? _c(
                                                                        "ThinCheckIcon",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#3034F7",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              src: require("@/assets/images/register-funnel/cross.svg"),
                                                                              alt: "Check",
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pricing-plans__features-props__item__marks-list__item",
                                                          },
                                                          [
                                                            feature.isCustomField
                                                              ? [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        feature.customTextPro
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              : [
                                                                  feature.isPro
                                                                    ? _c(
                                                                        "ThinCheckIcon",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#3034F7",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              src: require("@/assets/images/register-funnel/cross.svg"),
                                                                              alt: "Check",
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pricing-plans__features-props__item__marks-list__item",
                                                          },
                                                          [
                                                            feature.isCustomField
                                                              ? [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        feature.customTextEnterprise
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              : [
                                                                  feature.isEnterprise
                                                                    ? _c(
                                                                        "ThinCheckIcon",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#3034F7",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "pricing-plans__features-props__item__marks-list__item__icon",
                                                                          attrs:
                                                                            {
                                                                              src: require("@/assets/images/register-funnel/cross.svg"),
                                                                              alt: "Check",
                                                                            },
                                                                        }
                                                                      ),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isOpenedFeaturesListOnMobile
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "pricing-plans__features-mobile-list",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-mobile-list__item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-mobile-list__item__heading",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features-mobile-list__item__heading__main",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features-mobile-list__item__heading__title",
                                                },
                                                [_vm._v("Basic")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__list__item__content__price",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__currency",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentCurrencySymbol
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__amount",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.isYearly
                                                              ? _vm.basicPriceAnnual
                                                              : _vm.basicPriceMonthly
                                                          )
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__amount__period",
                                                        },
                                                        [_vm._v("/mo")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.isOpenedBasicFeaturesList &&
                                          !_vm.checkIsAbilityToUpdatePlan(
                                            _vm.getCurrentPlanById(
                                              _vm.isYearly
                                                ? _vm.M_BASIC_ANNUAL
                                                : _vm.M_BASIC_MONTHLY
                                            )
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__heading__label-action__text",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.onChoosePlan(
                                                        _vm.getCurrentPlanById(
                                                          _vm.isYearly
                                                            ? _vm.M_BASIC_ANNUAL
                                                            : _vm.M_BASIC_MONTHLY
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.getCurrentPlanById(
                                                    _vm.isYearly
                                                      ? _vm.M_BASIC_ANNUAL
                                                      : _vm.M_BASIC_MONTHLY
                                                  ).isCurrentPlan
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pricing.currentPlan"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    : _vm.getCurrentPlanById(
                                                        _vm.isYearly
                                                          ? _vm.M_BASIC_ANNUAL
                                                          : _vm.M_BASIC_MONTHLY
                                                      ).isNewPlan
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              _vm.planChangeText(
                                                                _vm.getCurrentPlanById(
                                                                  _vm.isYearly
                                                                    ? _vm.M_BASIC_ANNUAL
                                                                    : _vm.M_BASIC_MONTHLY
                                                                )
                                                              )
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              _vm.planChangeText(
                                                                _vm.getCurrentPlanById(
                                                                  _vm.isYearly
                                                                    ? _vm.M_BASIC_ANNUAL
                                                                    : _vm.M_BASIC_MONTHLY
                                                                )
                                                              )
                                                            )
                                                          ) + " "
                                                        ),
                                                      ],
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.isOpenedBasicFeaturesList
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features-props",
                                            },
                                            _vm._l(
                                              _vm.featuresList,
                                              function (featureSection) {
                                                return _c(
                                                  "div",
                                                  { key: featureSection.title },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pricing-plans__features-props__item-heading",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            featureSection.title
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      featureSection.items,
                                                      function (feature) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: feature.title,
                                                            staticClass:
                                                              "pricing-plans__features-props__item",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pricing-plans__features-props__item__value",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__value__title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        feature.title
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__value__description",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        feature.description
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pricing-plans__features-props__item__marks-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__marks-list__item",
                                                                  },
                                                                  [
                                                                    feature.isCustomField
                                                                      ? [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                feature.customTextBasic
                                                                              ) +
                                                                              "\n                          "
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          feature.isBasic
                                                                            ? _c(
                                                                                "ThinCheckIcon",
                                                                                {
                                                                                  staticClass:
                                                                                    "pricing-plans__features-props__item__marks-list__item__icon",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#3034F7",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "pricing-plans__features-props__item__marks-list__item__icon",
                                                                                  attrs:
                                                                                    {
                                                                                      src: require("@/assets/images/register-funnel/cross.svg"),
                                                                                      alt: "Check",
                                                                                    },
                                                                                }
                                                                              ),
                                                                        ],
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-mobile-list__item pricing-plans__features-mobile-list__item--popular",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-mobile-list__item__heading",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features-mobile-list__item__heading__main",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features-mobile-list__item__heading__title",
                                                },
                                                [_vm._v("Pro")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__list__item__content__price",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__currency",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentCurrencySymbol
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__amount",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.isYearly
                                                              ? _vm.proPriceAnnual
                                                              : _vm.proPriceMonthly
                                                          )
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "pricing-plans__list__item__content__price__amount__period",
                                                        },
                                                        [_vm._v("/mo")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.isOpenedProFeaturesList &&
                                          !_vm.checkIsAbilityToUpdatePlan(
                                            _vm.getCurrentPlanById(
                                              _vm.isYearly
                                                ? _vm.M_PROFESSIONAL_ANNUAL
                                                : _vm.M_PROFESSIONAL_MONTHLY
                                            )
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__heading__label-action__text",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.onChoosePlan(
                                                        _vm.getCurrentPlanById(
                                                          _vm.isYearly
                                                            ? _vm.M_PROFESSIONAL_ANNUAL
                                                            : _vm.M_PROFESSIONAL_MONTHLY
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.getCurrentPlanById(
                                                    _vm.isYearly
                                                      ? _vm.M_PROFESSIONAL_ANNUAL
                                                      : _vm.M_PROFESSIONAL_MONTHLY
                                                  ).isCurrentPlan
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pricing.currentPlan"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    : _vm.getCurrentPlanById(
                                                        _vm.isYearly
                                                          ? _vm.M_PROFESSIONAL_ANNUAL
                                                          : _vm.M_PROFESSIONAL_MONTHLY
                                                      ).isNewPlan
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              _vm.planChangeText(
                                                                _vm.getCurrentPlanById(
                                                                  _vm.isYearly
                                                                    ? _vm.M_PROFESSIONAL_ANNUAL
                                                                    : _vm.M_PROFESSIONAL_MONTHLY
                                                                )
                                                              )
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              _vm.planChangeText(
                                                                _vm.getCurrentPlanById(
                                                                  _vm.isYearly
                                                                    ? _vm.M_PROFESSIONAL_ANNUAL
                                                                    : _vm.M_PROFESSIONAL_MONTHLY
                                                                )
                                                              )
                                                            )
                                                          ) + " "
                                                        ),
                                                      ],
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.isOpenedProFeaturesList
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features-props",
                                            },
                                            _vm._l(
                                              _vm.featuresList,
                                              function (featureSection) {
                                                return _c(
                                                  "div",
                                                  { key: featureSection.title },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pricing-plans__features-props__item-heading",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            featureSection.title
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      featureSection.items,
                                                      function (feature) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: feature.title,
                                                            staticClass:
                                                              "pricing-plans__features-props__item",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pricing-plans__features-props__item__value",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__value__title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        feature.title
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__value__description",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        feature.description
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pricing-plans__features-props__item__marks-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__marks-list__item",
                                                                  },
                                                                  [
                                                                    feature.isCustomField
                                                                      ? [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                feature.customTextPro
                                                                              ) +
                                                                              "\n                          "
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          feature.isPro
                                                                            ? _c(
                                                                                "ThinCheckIcon",
                                                                                {
                                                                                  staticClass:
                                                                                    "pricing-plans__features-props__item__marks-list__item__icon",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#3034F7",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "pricing-plans__features-props__item__marks-list__item__icon",
                                                                                  attrs:
                                                                                    {
                                                                                      src: require("@/assets/images/register-funnel/cross.svg"),
                                                                                      alt: "Check",
                                                                                    },
                                                                                }
                                                                              ),
                                                                        ],
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-mobile-list__item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-mobile-list__item__heading",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features-mobile-list__item__heading__main",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__features-mobile-list__item__heading__title",
                                                },
                                                [_vm._v("Enterprise")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pricing-plans__list__item__content__price",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pricing.customQuote"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.isOpenedEnterpriseFeaturesList &&
                                          !_vm.checkIsAbilityToUpdatePlan(
                                            _vm.getCurrentPlanById(
                                              _vm.isYearly
                                                ? _vm.M_ENTERPRISE_ANNUAL
                                                : _vm.M_ENTERPRISE_MONTHLY
                                            )
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "price-list__plans-list__item__heading__label-action__text",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.onChoosePlan(
                                                        _vm.getCurrentPlanById(
                                                          _vm.isYearly
                                                            ? _vm.M_ENTERPRISE_ANNUAL
                                                            : _vm.M_ENTERPRISE_MONTHLY
                                                        )
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.getCurrentPlanById(
                                                    _vm.isYearly
                                                      ? _vm.M_ENTERPRISE_ANNUAL
                                                      : _vm.M_ENTERPRISE_MONTHLY
                                                  ).isCurrentPlan
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "pricing.currentPlan"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    : _vm.getCurrentPlanById(
                                                        _vm.isYearly
                                                          ? _vm.M_ENTERPRISE_ANNUAL
                                                          : _vm.M_ENTERPRISE_MONTHLY
                                                      ).isNewPlan
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              _vm.planChangeText(
                                                                _vm.getCurrentPlanById(
                                                                  _vm.isYearly
                                                                    ? _vm.M_ENTERPRISE_ANNUAL
                                                                    : _vm.M_ENTERPRISE_MONTHLY
                                                                )
                                                              )
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              _vm.planChangeText(
                                                                _vm.getCurrentPlanById(
                                                                  _vm.isYearly
                                                                    ? _vm.M_ENTERPRISE_ANNUAL
                                                                    : _vm.M_ENTERPRISE_MONTHLY
                                                                )
                                                              )
                                                            )
                                                          ) + " "
                                                        ),
                                                      ],
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.isOpenedEnterpriseFeaturesList
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pricing-plans__features-props",
                                            },
                                            _vm._l(
                                              _vm.featuresList,
                                              function (featureSection) {
                                                return _c(
                                                  "div",
                                                  { key: featureSection.title },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pricing-plans__features-props__item-heading",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            featureSection.title
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      featureSection.items,
                                                      function (feature) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: feature.title,
                                                            staticClass:
                                                              "pricing-plans__features-props__item",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pricing-plans__features-props__item__value",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__value__title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        feature.title
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__value__description",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        feature.description
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pricing-plans__features-props__item__marks-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pricing-plans__features-props__item__marks-list__item",
                                                                  },
                                                                  [
                                                                    feature.isCustomField
                                                                      ? [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                feature.customTextEnterprise
                                                                              ) +
                                                                              "\n                          "
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          feature.isEnterprise
                                                                            ? _c(
                                                                                "ThinCheckIcon",
                                                                                {
                                                                                  staticClass:
                                                                                    "pricing-plans__features-props__item__marks-list__item__icon",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#3034F7",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "pricing-plans__features-props__item__marks-list__item__icon",
                                                                                  attrs:
                                                                                    {
                                                                                      src: require("@/assets/images/register-funnel/cross.svg"),
                                                                                      alt: "Check",
                                                                                    },
                                                                                }
                                                                              ),
                                                                        ],
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-mobile-list__button",
                                      on: { click: _vm.returnToAllPlans },
                                    },
                                    [
                                      _c("ArrowLongIcon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          color: "rgb(135, 136, 148)",
                                          direction: "left",
                                        },
                                      }),
                                      _vm._v(
                                        "\n\n                Return to All Plans\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }