<template>
  <svg
    viewBox="0 0 16 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    :width="width"
    :height="height"
    :color="color"
    :style="rotationStyles"
  >
    <path d="M6.18329 0.842714L0.815366 6.67612C0.604004 6.90217 0.5 7.20113 0.5 7.50009C0.5 7.79905 0.604842 8.09729 0.814527 8.32479L6.18245 14.1582C6.60182 14.6139 7.2812 14.6139 7.70057 14.1582C8.11994 13.7025 8.11994 12.9642 7.70057 12.5084L4.16361 8.66677H14.4566C15.0501 8.66677 15.5 8.14432 15.5 7.5329C15.5 6.92149 15.0501 6.33341 14.4566 6.33341H4.16361L7.69973 2.49065C8.1191 2.03492 8.1191 1.29663 7.69973 0.840891C7.28036 0.385156 6.60266 0.386979 6.18329 0.842714Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowLongIcon',

  props: {
    color: {
      type: String,
      default: '#ffffff',
    },

    width: {
      type: Number,
      default: 16,
    },

    height: {
      type: Number,
      default: 15,
    },

    direction: {
      type: String,
      default: 'left',
    },
  },

  computed: {
    rotationStyles () {
      let angle = 0

      switch (this.direction) {
        case 'top':
          angle = 90

          break
        case 'bottom':
          angle = 270

          break
        case 'left':
          angle = 0

          break
        case 'right':
          angle = 180

          break
      }

      return `transform: rotate(${angle}deg);`
    },
  }
}
</script>
